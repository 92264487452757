import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { CookiesModule, GOOGLE_GAT_GTAG, IS_FACEBOOK } from '@wienerberger/components';
import { GOOGLE_TAG_MANAGER_ID, UtilsService } from '@wienerberger/services';
import { CookiesState } from '@wienerberger/states';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_JWT_TOKEN, COOKIES_KEY } from './app.consts';
import { AppRoutingModule } from './app.routing';
import { AppState, AppStateLogout, AppStateSetUserData } from './app.state';
import { LoginState } from './pages/auth/pages/main/pages/login/login.state';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { UserService } from './services/user.service';
import { firstValueFrom, of } from 'rxjs';

const LOCAL_STORAGE_STATE_VALUES = [APP_JWT_TOKEN, COOKIES_KEY];

async function _initApplication(store: Store, userService: UserService) {
  const token = store.selectSnapshot(AppState.getToken);
  if (!token) {
    return of(null);
  }
  return await firstValueFrom(userService.getMe())
    .then((user) => {
      store.dispatch(new AppStateSetUserData(user));
    })
    .catch((reason) => {
      if (reason.status === 403) {
        store.dispatch(new AppStateLogout());
      }
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot([AppState, LoginState, CookiesState], {
      developmentMode: false,
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: [...LOCAL_STORAGE_STATE_VALUES],
    }),
    NgxsLoggerPluginModule.forRoot({
      logger: console,
      collapsed: false,
      disabled: environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    LeafletModule,
    RecaptchaV3Module,
    AppRoutingModule,
    CookiesModule,
  ],
  providers: [
    provideNgxMask(),
    UtilsService,
    { provide: GOOGLE_TAG_MANAGER_ID, useValue: environment.ga },
    { provide: GOOGLE_GAT_GTAG, useValue: environment.gat_gtag },
    { provide: IS_FACEBOOK, useValue: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => await _initApplication(store, userService),
      deps: [UserService, Store],
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Lc2e9saAAAAAD248akOKC3Horqh6ciQox0nFJYK',
    },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'pl' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
